const orderUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ORDER}`;
const accountsURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNTS}`;

export const OpportunityHistory = `${orderUrl}/opportunities`;
export const MilestoneDetails = `${orderUrl}/milestones`;

export const OrderWorkflowQuery = `${orderUrl}/retryorderflow/transactions`;
export const OrderWorkflowRetry = `${orderUrl}/retryorderflow/retry`;
export const OrderWorkflowCancel = `${orderUrl}/retryorderflow/cancel`;

export const getShipmentEventsByOpp = `${accountsURL}/shipment/events/opportunity`;
export const retryShipmentTask = `${accountsURL}/shipment/comments`;
